.navbar {
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.navbar-list-icon {
  color: black;
  font-size: 45px;
  display: none;
  cursor: pointer;
}

.navbar-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.shop-icon,
.user-icon {
  font-size: 25px;
  text-align: center;
  color: white;
  width: 40px;
  height: 40px;
  line-height: 1.6;
  cursor: pointer;
}

.shop-icon {
  background-color: purple !important;
  border-radius: 0 50% 50% 50%;
}

.user-icon {
  background-color: darkblue;
  border-radius: 50%;
}

.navbar-links {
  display: flex;
  margin-top: 14px;
  transition: 0.34s;
}

.navbar-close-icon {
  display: none;
}

.navbar-link {
  margin: 15px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 600;
  color: rgba(102, 51, 153, 0.85);
}

.navbar-link:hover {
  color: purple;
}

@media (max-width: 1199px) {
  .navbar-links {
    position: absolute;
    flex-direction: column;
    width: 350px;
    background-color: #ffffff;
    min-height: 100vh;
    top: 0px;
    right: -350px;
    margin-top: 0;
    z-index: 100;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  }

  .navbar-close-icon {
    display: block;
    position: absolute;
    right: 20px;
    top: 5px;
    font-size: 35px;
    cursor: pointer;
  }

  .navbar-close-icon:hover {
    color: purple;
  }

  .navbar-link {
    margin: 25px 10px;
  }

  .navbar-link:first-child {
    margin-top: 55px;
  }

  .navbar-list-icon {
    display: block;
  }
}

@media (max-width: 580px) {
  .navbar-links {
    width: 275px;
  }

  .navbar-link {
    margin: 18px 5px;
  }
}
