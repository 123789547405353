.register,
.login {
  margin: 30px 0;
  padding-bottom: 20px;
  height: calc(70vh - 100px);
}

.user-input,
.email-input,
.pass-input {
  margin-top: 30px;
}

.user-input {
  margin-top: 50px;
}

.email-input input,
.user-input input,
.pass-input input {
  width: 400px;
  height: 50px;
  padding: 5px 25px;
  border-radius: 25px;
  border: none;
  background-color: #cccccc57;
  outline: none;
}

.register-btn {
  margin-top: 25px;
  background-color: purple;
  display: inline-block;
  padding: 5px;
  border-radius: 25px;
  width: 250px;
  font-size: 21px;
  font-weight: 600;
  cursor: pointer;
  color: white !important;
}

.login-page,
.register-page {
  margin-top: 20px;
  font-size: 21px;
  font-weight: 300;
  color: #555;
}

.login-button,
.register-button {
  margin-left: 4px;
  color: purple;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}
