.footer {
  min-height: 35vh;
  background-color: rgba(128, 0, 128, 0.95);
  padding: 50px 0;
  color: white;
}

.footer-links-header,
.footer-social-title {
  font-size: 40px;
  margin-bottom: 3px;
}

.footer-links-item {
  margin-left: -28px;
}

.footer-link {
  font-size: 22px;
  margin-top: 28px;
  cursor: pointer;
}

.footer-link:hover,
.social-icon-title:hover {
  color: rgb(3, 169, 244);
}

.social-icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 21px;
}

.social-icon i {
  font-size: 27px;
  margin: 0 23px 0 0;
}

.social-icon-title {
  font-size: 22px;
  font-weight: 700;
}

@media (max-width: 991px) {
  .container-footer {
    flex-direction: column;
    text-align: center;
  }

  .social-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 580px) {
  .footer-links-header,
  .footer-social-title {
    font-size: 35px;
    margin-top: 5px;
  }

  .footer-social-title {
    margin-top: 20px;
  }
}
