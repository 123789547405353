.product {
  background-color: #cccccc44;
  padding: 40px 0 60px;
}

.container {
  gap: 20px 60px;
}

.product-item {
  width: 21%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 15px 20px;
  border-radius: 12px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.product-item-img {
  width: 175px;
  height: 200px;
}

.product-item-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-item-title {
  font-size: 15px;
  font-weight: 500;
  color: purple;
  margin-top: 20px;
  text-align: center;
  height: 83px;
}

.product-item-price {
  font-size: 18px;
  font-weight: bold;
  color: gray;
  margin-top: 12px;
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .product-item {
    width: 28%;
  }
}

@media (max-width: 991px) {
  .product-item {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .product-item {
    width: 60%;
  }

  .container {
    gap: 0px;
  }
}

@media (max-width: 500px) {
  .product-item {
    width: 80%;
  }
}
