.product-page {
  margin-top: 50px;
}

.product-page-items {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-page-item {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 15px 20px;
  border-radius: 12px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.filter {
  width: 25%;
  margin-top: 50px;
}

@media (max-width: 1400px) {
  .product-page-item {
    width: 48%;
  }
}

@media (max-width: 991px) {
  .product-page-items {
    justify-content: center;
    width: 75%;
  }

  .filter {
    width: 40%;
  }
  .product-page-item {
    width: 75%;
  }
}

@media (max-width: 991px) {
  .product-page-items {
    justify-content: center;
    width: 75%;
  }
}

@media (max-width: 767px) {
  .product-page-items {
    width: 100%;
  }

  .product-page-item {
    width: 70%;
  }

  .filter {
    width: 100%;
  }

  .container-product-page {
    display: flex;
    flex-flow: column-reverse;
  }
}
